
import './aos';
import './flickity';
import './jarallax';
import './navigation';
import './plyr';
import mrSmoothScroll from './smooth-scroll';
import mrSticky from './sticky';

(() => {
  if (typeof $ === 'undefined') {
    throw new TypeError('Medium Rare JavaScript requires jQuery. jQuery must be included before theme.js.');
  }
})();

export {
  mrSmoothScroll,
  mrSticky
};
